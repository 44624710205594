import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Card from '../components/card'

const BlogHeroCard = ({ slug, frontmatter, excerpt }) => {
  const heroImage = frontmatter.heroImage
  const image = getImage(heroImage.file)
  const alt = heroImage.alt || ""

  return (
    <Card>
      <Link to={`posts/${slug}`}>
        <div className="p-2 space-y-3">
          <div>
            <GatsbyImage className="h-48 rounded-t-md md:h-72 lg:h-96" image={image} alt={alt}/>
          </div>
          <div>
            <p className="text-xl text-teal-600 dark:text-teal-400">{frontmatter.title}</p>
            <p className="text-xs text-zinc-400">{frontmatter.date}</p>
          </div>
          <div className="text-sm line-clamp-5 md:line-clamp-none">
            {excerpt}
          </div>
        </div>
      </Link>
    </Card>
  )
}

const BlogCard = ({ slug, frontmatter, excerpt }) => {
  const heroImage = frontmatter.heroImage
  const image = getImage(heroImage.file)
  const alt = heroImage.alt || ""

  return (
    <Card>
      <Link to={`posts/${slug}`}>
        <div className="p-2 grid grid-cols-3 space-x-3">
          <div className="col-span-1">
            <GatsbyImage className="h-28 rounded-l-md md:h-32 lg:h-36" image={image} alt={alt}/>
          </div>
          <div className="space-y-4 col-span-2">
            <div>
              <p className="text-lg text-teal-600 dark:text-teal-400">{frontmatter.title}</p>
              <p className="text-xs text-zinc-400">{frontmatter.date}</p>
            </div>
            <p className="text-xs line-clamp-3 md:line-clamp-4 lg:line-clamp-5">{excerpt}</p>
          </div>
        </div>
      </Link>
    </Card>
  )
}

const BlogPage = ({ data }) => {
  const pages = data.allFile.nodes.map(node => node.childMdx);
  const latest = pages.shift();
  return (
    <Layout pageTitle="Home">
      <BlogHeroCard {...latest}/>
      <div className="space-y-3 hidden sm:block">
        {
          pages.reduce((result, _, index, array) => index % 2 === 0 ? [...result, array.slice(index, index + 2)] : result, []).map(pairs => {
            const key = pairs.reduce((a, b) => a.id + b.id)
            return (
              <div key={key} className="grid grid-cols-2 space-x-3">
                {
                  pairs.map(node => (
                    <BlogCard key={node.id} {...node} />
                  ))
                }
              </div>
            )
          })
        }
      </div>
      <div className="space-y-2 sm:hidden">
        {
          pages.map(node => (
            <BlogCard key={node.id} {...node} />
          ))
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(
      sort: {fields: childMdx___frontmatter___date, order: DESC}
      filter: {sourceInstanceName: {eq: "blog"}, extension: {eq: "mdx"}}
    ) {
      nodes {
        childMdx {
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            heroImage {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          id
          slug
          excerpt(pruneLength: 750)
        }
      }
    }
  }
`

export default BlogPage
